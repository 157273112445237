import Styles from '../styles/Loaders.module.css'
export const Spinner = () => {
  return (
    <section className={Styles.container}>
      <span className={Styles.spinner} />
    </section>
  )
}
export const Pulsers = () => {
  return (
    <section className={Styles.container}>
      <span className={Styles.pulser} />
      <span className={Styles.pulser} />
      <span className={Styles.pulser} />
    </section>
  )
}
