import { createContext, useContext, useEffect, useState, React } from 'react'
import PropTypes from 'prop-types'
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword
} from 'firebase/auth'
import { auth } from '../firebase'

export const authContext = createContext()

// es un hook personalizado useAuth
export const useAuth = () => {
  const context = useContext(authContext)
  if (!context) throw new Error('There is no Auth provider')
  return context
}

export function AuthProvider ({ children }) {
  const [user, setUser] = useState(null) // for AuthState change
  const [loading, setLoading] = useState(true)
  const login = async (email, password) => {
    const res = await fetch(process.env.SV_URI + '/api/v1/user/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
    const token = await res.json()
    window.localStorage.setItem('authToken', token.result)
    const user = await signInWithEmailAndPassword(auth, email, password)
    return user
  }
  const logout = () => signOut(auth)
  const sendResetPassword = async (email) => await sendPasswordResetEmail(auth, email)
  const signup = (email, password) => createUserWithEmailAndPassword(auth, email, password)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
      setLoading(false)
    })
    return () => unsubscribe()
  }, [])

  return (
    <authContext.Provider value={{ login, user, logout, loading, sendResetPassword, signup }}>
      {children}
    </authContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any
}
