import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { AuthProvider } from './context/authContext'
import { ProtectedRoute } from './components/Protection/ProtectedRoute'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Spinner } from './components/Loaders'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/Login'))
const Page404 = React.lazy(() => import('./views/pages/Page404'))
const Page500 = React.lazy(() => import('./views/pages/Page500'))

class App extends Component {
  render () {
    return (
      <AuthProvider>
        <BrowserRouter>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path='/login' name='Login Page' element={<Login />} />
              <Route path='/404' name='Page 404' element={<Page404 />} />
              <Route path='/500' name='Page 500' element={<Page500 />} />
              <Route
                path='*'
                name='Home'
                element={
                  <ProtectedRoute>
                    <DefaultLayout />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Suspense>
        </BrowserRouter>
        <ToastContainer />
      </AuthProvider>
    )
  }
}

export default App
